<template>
  <div>
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>数据分析</el-breadcrumb-item>
        <el-breadcrumb-item>题目分析</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div style="padding: 10px">
      <div class="top">
        <Input class="search-bar" search placeholder="题干搜索" @on-search="search" v-model="keyword" />
      </div>
      <Table :height="th" :data="questionData" :columns="questionColumn" :loading="loading">
        <template slot-scope="{ row, index }" slot="stem">
          <Tooltip placement="top" transfer style="width: 100%" max-width="100%">
            <div slot="content">
              <div v-html="row.stem"></div>
            </div>
            <pre v-html="row.stem" style="width: 100%" />
          </Tooltip>
        </template>
        <template slot-scope="{ row, index }" slot="diff">
          <span>{{ difficulty[row.difficulty].label }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="type">
          <span>{{ questionType[row.questionType].label }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="wrong">
          <span>{{ Math.round(row.wrongRate * 100) }}%</span>
        </template>
      </Table>
      <Page
        class="yt-page"
        :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
        @on-change="changePage"
        :page-size-opts="[10, 20, 40, 100]"
        @on-page-size-change="changeSize"
        :total="total"
        show-sizer
        show-elevator
        show-total
      />
    </div>
  </div>
</template>

<script>
import { difficulty } from '@util/difficulty'
import { questionType } from '@util/questionType'
import statisticApi from '@api/statistic'

export default {
  name: 'Question',
  data() {
    return {
      difficulty: difficulty,
      questionType: questionType,
      keyword: '',
      questionColumn: [
        {
          title: '题干',
          slot: 'stem'
        },
        {
          title: '难度',
          slot: 'diff',
          width: 150
        },
        {
          title: '题型',
          slot: 'type',
          width: 150
        },
        {
          title: '错误率',
          slot: 'wrong',
          sortable: true,
          width: 150
        },
        {
          title: '做题次数',
          key: 'doCount',
          width: 200
        }
      ],
      questionData: [],
      page: 0,
      size: 10,
      th: document.body.clientHeight - 185,
      total: 0,
      loading: false
    }
  },
  created() {
    this.initQuestion()
  },
  methods: {
    initQuestion() {
      this.loading = true
      statisticApi
        .getQuestionA(this.page, this.size, this.keyword)
        .then(res => {
          this.questionData = res.res.data.map(item => {
            item.stem = this.cleanWord(item.stem)
            return item
          })
          this.total = res.res.total
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    changePage(val) {
      this.page = val - 1
      this.initQuestion()
    },
    changeSize(val) {
      this.size = val
      this.initQuestion()
    },
    search() {
      this.page = 0
      this.size = 10
      this.initQuestion()
    }
  }
}
</script>

<style scoped lang="less">
.top {
  height: 50px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 0 5px rgba(7, 27, 41, 0.1);
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  .search-bar {
    width: 300px;
    ::v-deep .ivu-input {
      border: 0;
      border-bottom: 1px solid #ccc;
      border-radius: 0;
      &:focus {
        box-shadow: 0 1px 0 rgba(45, 140, 240, 0.2);
      }
    }
  }
}
</style>
<style lang="less">
pre {
  word-wrap: break-word;
  white-space: pre-wrap;
}
</style>
